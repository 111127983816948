export default function(context) {
  const { store } = context
  context.userAgent = process.server
    ? context.req.headers['user-agent']
    : navigator.userAgent
  if (context.isMobile) {
    context.layout = 'mobile'
  } else {
    context.layout = 'default'
  }
  store.commit('setState', { layout: context.layout })
}
