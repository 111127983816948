const cfg = require(`../configs/${process.env.NODE_ENV}.json`)

export default function(ctx, inject) {
  function endpoint(
    action,
    page = false,
    limit = false,
    order = null,
    orderDirection = null,
    optionalQuery = ''
  ) {
    // console.log(action)
    let url = cfg.ghost.api_url + action + '/?key=' + cfg.ghost.key
    if (page) {
      url += '&page=' + page
    }
    if (limit) {
      url += '&limit=' + limit
    }
    if (order && orderDirection) {
      url += '&order=' + order + ' ' + orderDirection
    }
    // console.log(url + optionalQuery)
    return url + optionalQuery
  }

  inject('ghost', {
    tags: async () => {
      const res = await ctx.$axios.get(endpoint('posts'))
      if ('tags' in res.data === false || 'meta' in res.data === false) {
        return {}
      }

      const returnItems = {
        tags: [],
        meta: res.data.meta
      }

      Object.keys(res.data.tags).forEach(function(key) {
        const thisTag = res.data.tags[key]
        const length = cfg.ghost.ignore_tags.filter((tag) => {
          return tag === thisTag.slug
        }).length

        if (length > 0) {
          returnItems.meta.pagination.total--
        } else {
          returnItems.tags.push(thisTag)
        }
      })
      return returnItems
    },
    tag: async (slug) => {
      const res = await ctx.$axios.get(endpoint('tags/slug/' + slug))
      return res.data
    },
    posts: async (
      page = false,
      limit = 100,
      order = null,
      orderDirection = null,
      tags = []
    ) => {
      let optionalQuery = '&include=tags'
      if (tags.length > 0) {
        optionalQuery = '&filter=tags:' + tags.join()
      }

      const res = await ctx.$axios.get(
        endpoint('posts', page, limit, order, orderDirection, optionalQuery)
      )
      return res.data
    },
    post: async (slug) => {
      const res = await ctx.$axios.get(
        endpoint('posts/slug/' + slug, 1, 1, null, null, '&include=tags')
      )
      return res.data
    }
  })
}
