<template>
  <div>
    <div class="container web-content">
      <div v-if="error.statusCode === 404" id="notfound">
        <div class="notfound">
          <div class="notfound-404">
            <h1>404</h1>
          </div>
          <h2>We are sorry, Page not found!</h2>
          <p class="mt-4">
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <a href="/" class="mt-4 button-blue-gradient">Back To Homepage</a>
        </div>
      </div>
      <div
        v-if="
          error.statusCode === 500 ||
            error.statusCode === 502 ||
            error.statusCode === 503 ||
            error.statusCode === 504
        "
        id="notfound"
      >
        <div class="notfound">
          <div class="notfound-404">
            <h1></h1>
          </div>
          <div class="d-block text-center">
            <img src="/images/logo@2x.png" class="mx-auto w-100" />
          </div>

          <h2 class="mt-5">ระบบขัดข้องชั่วคราว</h2>
          <p class="mt-4">
            ขออภัยในความไม่สะดวก เว็บไซต์ Dezpax
            จะกลับมาใช้งานได้อีกครั้งในไม่ช้า กรุณารอสักครู่ค่ะ
          </p>
          <a href="https://lin.ee/K0PgOze" class="mt-4 button-blue-gradient"
            >ติดต่อเจ้าหน้าที่</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
  layout: 'error' // you can set a custom layout for the error page
}
</script>

<style lang="scss">
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Maven Pro', sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-family: 'Maven Pro', sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  font-family: 'Maven Pro', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0098ef;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: #0098ef;
  color: #0098ef;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  .notfound h2 {
    font-size: 26px;
  }
}
</style>
