export const state = () => ({
  advertise: [],
  isClose: true
})
export const mutations = {
  updateAdvertise(state, value) {
    state.advertise = value
  },
  closeModal(state, value) {
    state.isClose = value
  }
}
export const actions = {
  getAdvertiseActive(context) {
    return this.$axios.get('/api/get-advertise-active/').then((res) => {
      if (res.data.length > 0) {
        context.commit('updateAdvertise', res.data)
      } else {
        context.commit('closeModal', false)
      }
    })
  }
}
