const createHmac = require('create-hmac')
export default class ImgProxy {
  constructor(config) {
    this.config = config
  }

  // getStaticUrl(url, width, height) {
  //   if (
  //     typeof this.config.staticBaseUrl !== 'undefined' &&
  //     this.config.staticBaseUrl !== ''
  //   ) {
  //     url = this.config.staticBaseUrl + url
  //   }
  //   return this.getUrl(url, width, height)
  // }

  getUrl(url, width, height) {
    const urlSafeBase64 = (string) => {
      return Buffer.from(string)
        .toString('base64')
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
    }

    const hexDecode = (hex) => {
      return Buffer.from(hex, 'hex')
    }
    const sign = (salt, target, secret) => {
      const hmac = createHmac('sha256', hexDecode(secret))
      hmac.update(hexDecode(salt))
      hmac.update(target)
      return urlSafeBase64(hmac.digest())
    }

    const resizingType = 'fill'
    const gravity = 'sm'
    const enlarge = 0
    // const extension = 'png'
    const encodedUrl = urlSafeBase64(url)
    // const path = `/${resizingType}/${width}/${height}/${gravity}/${enlarge}/${encodedUrl}.${extension}`
    const path = `/${resizingType}/${width}/${height}/${gravity}/${enlarge}/${encodedUrl}`

    const signature = sign(this.config.salt, path, this.config.key)
    return `${this.config.cdn}/${signature}${path}`
  }
}
