export const state = () => ({
  // products list
  listOfProductsBox: [],
  listOfProductsBowl: [],
  listOfProductsCup: [],
  listOfProductsBottle: [],
  listOfProductsBag: [],
  listOfProductsPlasticBag: [],
  listOfProductsBakeryDessert: [],
  listOfProductsCorrugatedBox: [],
  listOfProductsAccessories: [],
  listOfProductsBagScreenLogo: [],
  listOfProductsCommerce: [],

  // filter
  boxFilterOption: [],
  bowlFilterOption: [],
  cupFilterOption: [],
  bottleFilterOption: [],
  listOfProductsFlexiblePackaging: []
})
export const mutations = {
  updateListOfProductsBox(state, value) {
    state.listOfProductsBox = value
  },
  updateListOfProductsBowl(state, value) {
    state.listOfProductsBowl = value
  },
  updateListOfProductsCup(state, value) {
    state.listOfProductsCup = value
  },
  updateListOfProductsBottle(state, value) {
    state.listOfProductsBottle = value
  },
  updateListOfProductsBag(state, value) {
    state.listOfProductsBag = value
  },
  updateListOfProductsPlasticBag(state, value) {
    state.listOfProductsPlasticBag = value
  },
  updateListOfProductsBakeryDessert(state, value) {
    state.listOfProductsBakeryDessert = value
  },
  updateListOfProductsCorrugatedBox(state, value) {
    state.listOfProductsCorrugatedBox = value
  },
  updateListOfProductsAccessories(state, value) {
    state.listOfProductsAccessories = value
  },
  updateListOfProductsBagScreenLogo(state, value) {
    state.listOfProductsBagScreenLogo = value
  },
  updateBoxFilterOption(state, value) {
    state.boxFilterOption = value
  },
  updateBowlFilterOption(state, value) {
    state.bowlFilterOption = value
  },
  updateCupFilterOption(state, value) {
    state.cupFilterOption = value
  },
  updateBottleFilterOption(state, value) {
    state.bottleFilterOption = value
  },
  updateListOfProductsFlexiblePackaging(state, value) {
    state.listOfProductsFlexiblePackaging = value
  },
  updateListOfProductsCommerce(state, value) {
    state.listOfProductsCommerce = value
  }
}
export const actions = {
  getListBoxProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-box-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsBox', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },
  getListBowlProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-bowl-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsBowl', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },
  getListCupProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-cup-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsCup', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },
  getListBottleProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-bottle-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsBottle', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },
  getListBagProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-bag-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsBag', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },
  getListPlasticBagProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-plastic-bag-product/?start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsPlasticBag', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },

  getListBakeryDessertProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-bakery-dessert-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len +
          '&product_dezpax_channel=food'
      )
      .then((res) => {
        context.commit('updateListOfProductsBakeryDessert', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },

  getListCorrugatedBoxProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-corrugated-box-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsCorrugatedBox', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },

  getListAccessoriesProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-accessories-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len +
          '&product_dezpax_channel=food'
      )
      .then((res) => {
        context.commit('updateListOfProductsAccessories', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },

  getListBagScreenLogoProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-bag-screen-logo-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsBagScreenLogo', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },
  getListFlexiblePackagingProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-flexible-packaging-product/?dezpax_channel=food&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updateListOfProductsFlexiblePackaging', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  },
  getBoxFilterOption(context) {
    return this.$axios.post('/api/get-box-filter/').then((res) => {
      context.commit('updateBoxFilterOption', res.data)
    })
  },
  getBowlFilterOption(context) {
    return this.$axios.post('/api/get-bowl-filter/').then((res) => {
      context.commit('updateBowlFilterOption', res.data)
    })
  },
  getCupFilterOption(context) {
    return this.$axios.post('/api/get-cup-filter/').then((res) => {
      context.commit('updateCupFilterOption', res.data)
    })
  },
  getBottleFilterOption(context) {
    return this.$axios.post('/api/get-cup-filter/').then((res) => {
      context.commit('updateBottleFilterOption', res.data)
    })
  },

  getListCommerceProduct(context, param) {
    return this.$axios
      .post(
        '/api/get-commerce-product/?start=' +
          param.start +
          '&length=' +
          param.len +
          '&product_type_id=' +
          param.productTypeId +
          '&type_id=' +
          param.typeId +
          '&product_dezpax_channel=comm'
      )
      .then((res) => {
        context.commit('updateListOfProductsCommerce', {
          data: res.data
          // loadMore: param.loadMore
        })
      })
  }
}
