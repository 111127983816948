import cookieparser from 'cookieparser'
import jsCookie from 'js-cookie'

export const state = () => {
  return {
    token: null,
    locales: ['en', 'th'],
    locale: 'th',
    layout: 'default'
  }
}
export const mutations = {
  setState(state, values) {
    for (const key in values) {
      state[key] = values[key]
    }
  },
  setLang(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
      jsCookie.set('locale', locale)
    }
  }
}
export const getters = {}
export const actions = {
  nuxtServerInit({ commit }, { req }) {
    if (req.headers.cookie !== undefined && req.headers.cookie !== null) {
      const parsed = cookieparser.parse(req.headers.cookie)

      const token = parsed.token
      commit('setState', { token: token !== undefined ? token : null })

      const locale = parsed.locale
      commit('setState', { locale: locale !== undefined ? locale : null })
    }
  }
}
