export const state = () => ({
  tabIndex: 0,
  siteData: [],
  globalSetting: []
})
export const mutations = {
  updateTabIndex(state, value) {
    state.tabIndex = value
  },
  updateSiteData(state, value) {
    state.siteData = value
  },
  updateGlobalSetting(state, value) {
    state.globalSetting = value
  }
}
export const actions = {
  getTabIndex(context) {
    context.commit('updateTabIndex', 0)
  },
  getWebSetting(context, param) {
    return this.$axios
      .get('/api/get-web-setting/?page=' + encodeURI(param.pageName))
      .then((res) => {
        context.commit('updateSiteData', res.data)
      })
  },
  getGlobalSetting(context, param) {
    return this.$axios.get('/api/get-global-setting/').then((res) => {
      context.commit('updateGlobalSetting', res.data)
    })
  }
}
