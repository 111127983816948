export const state = () => ({
  galleryList: [],
  galleryPictureList: []
})
export const mutations = {
  updateProductGallery(state, value) {
    state.galleryList = value
  },
  updatePictureByGallery(state, { value, loadMore }) {
    if (loadMore) {
      state.galleryPictureList = state.galleryPictureList.concat(value)
    } else {
      state.galleryPictureList = value
    }
  }
}
export const actions = {
  getProductGallery(context) {
    return this.$axios.get('/api/get-gallery-list/').then((res) => {
      context.commit('updateProductGallery', res.data)
    })
  },
  getPictureByGallery(context, param) {
    return this.$axios
      .get(
        '/api/get-picture-by-gallery/?gallery_id=' +
          param.galleryId +
          '&start=' +
          param.start +
          '&length=' +
          param.len
      )
      .then((res) => {
        context.commit('updatePictureByGallery', {
          value: res.data.data,
          loadMore: param.loadMore
        })
      })
  }
}
