/* eslint-disable no-console */
const cfg = require(`../configs/${process.env.NODE_ENV}.json`)

export default function(ctx) {
  // { $axios, redirect }

  ctx.$axios.setBaseURL(encodeURI(cfg.axios.apiBaseURL))
  ctx.$axios.onRequest((config) => {
    // console.log('Making request to ' + config.url)
    const store = ctx.store
    if (store.state.token !== undefined && store.state.token !== null) {
      config.headers.common.Authorization = store.state.token
    }
  })

  ctx.$axios.onResponse((response) => {
    const headers = response.headers
    const token = headers.Authorization
    if (token !== undefined) {
      const store = ctx.store
      store.commit('setState', { token: `${token}` })
    }
    return response
  })

  ctx.$axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      ctx.redirect('/')
    }
  })
}
