import Vue from 'vue'
import th from 'vee-validate/dist/locale/th.json'
// eslint-disable-next-line import/no-duplicates
import * as VeeValidate from 'vee-validate'
// eslint-disable-next-line import/no-duplicates
import { extend, localize } from 'vee-validate'
// import { required, alpha, email } from 'vee-validate/dist/rules'
import * as rules from 'vee-validate/dist/rules'

Vue.directive(VeeValidate)

localize('th', th)

extend('email_lower', {
  validate: (value) => {
    const strongRegex = new RegExp(
      "^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$"
    )
    return strongRegex.test(value)
  },
  message: 'อีเมล์ต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็กทั้งหมด'
})

extend('verify_password', {
  validate: (value) => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')
    return strongRegex.test(value)
  },
  message:
    'Password ต้องประกอบด้วยตัวอีกษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว พิมพ์เล็กอย่างน้อย 1 ตัว และตัวเลขอย่างน้อย 1 ตัว'
})

extend('phone', {
  validate: (value) => {
    const strongRegex = new RegExp('^(?=.*[0-9])')
    return strongRegex.test(value)
  },
  message: 'กรุณาระบุเบอร์โทรศัพท์มือถือจำนวน 10 หลักเป็นตัวเลขเท่านั้น'
})

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  // eslint-disable-next-line import/namespace
  extend(rule, rules[rule])
})

// extend('required', {
//   ...required,
//   message: 'กรุณากรอก'
// })

// extend('alpha', {
//   ...alpha,
//   message: 'This field must only contain alphabetic characters'
// })

// extend('email', {
//   ...email,
//   message: 'รูปแบบอีเมลไม่ถูกต้อง'
// })

// extend('password', {
//   params: ['target'],
//   validate(value, { target }) {
//     return value === target
//   },
//   message: 'Password confirmation does not match'
// })
