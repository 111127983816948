import Vue from 'vue'
import cookieSetting from '~/plugins/cookieSetting.js'

const config = require(`../configs/${process.env.NODE_ENV}.json`)

Vue.use(cookieSetting)

// Vue.prototype.$liff = window.liff

Vue.mixin({
  data() {
    return {
      tabmenu_active: 'box',
      cartObjList: [],
      s3_path: config.s3,
      lineliffAuth: null,
      userId_stamp: null,
      hideFooterMenu: false
    }
  },
  beforeMount() {
    this.tabmenu_active = localStorage.getItem('tabmenu_active')
  },
  mounted() {
    // window.liff
    //   .init({
    //     liffId: '1654448827-OodGqQbZ'
    //   })
    //   .then(() => {
    //     if (window.liff.isLoggedIn()) {
    //       console.log('LIFF Authorized')
    //       window.liff.getProfile().then((data) => {
    //         this.lineProfileData = data
    //         // console.log(this.lineProfileData)
    //       })
    //     } else {
    //       console.log('LIFF Not Authorized')
    //       window.liff.login()
    //     }
    //   })
  },
  methods: {
    getUserData(authenToken, callback) {
      let userData = null

      // convert to form data
      const formData = new FormData()
      formData.append('access_token', authenToken)
      if (authenToken !== undefined) {
        // have token
        this.$axios
          .post('/api/get-user-info-by-access-token/', formData)
          .then((response) => {
            if (response.data.login_status !== false) {
              userData = response.data
            }
            callback(userData)
          })
      }
    },
    hideFooterMenuEvent() {
      this.$root.hideFooterMenu = true
    },
    showFooterMenuEvent() {
      this.$root.hideFooterMenu = false
    }
  }
})
