import Vue from 'vue'
import moment from 'moment'
import ImgProxy from '../libraries/ImgProxy.js'

const config = require(`../configs/${process.env.NODE_ENV}.json`)

const globalVariable = {}
globalVariable.s3 = config.s3
export default globalVariable

Vue.prototype.$getImage = (path) => {
  return config.s3 + '' + path
}

/* Global imgproxy */
const imgProxy = new ImgProxy(config.imgProxy)
Vue.prototype.$imgProxy = imgProxy

Vue.prototype.$getColorTag = (groupId) => {
  if (groupId === '1' || groupId === '4' || groupId === '6') {
    // plastic
    return 'tag-category-pink'
  } else if (groupId === '2' || groupId === '5' || groupId === '8') {
    // paper
    return 'tag-category-blue'
  } else if (groupId === '3' || groupId === '7') {
    // natural issue
    return 'tag-category-green'
  } else if (groupId === '45') {
    return 'tag-category-corrugated'
  }
  return 'tag-category-pink'
}

Vue.filter('dateFormat', function(date) {
  moment.locale('th')
  return moment(date).fromNow()
})

Vue.filter('ghostHttps', function(content) {
  if (content) {
    return content.replace('http://cms.dezpax.com', 'https://cms.dezpax.com')
  }
  return '/images/place-holder-264x198.png'
})

Vue.filter('dateLocal', function(date) {
  moment.locale('th')

  return moment(date).calendar('', {
    sameElse: 'DD/MM/YYYY H:mm'
  })
})
Vue.filter('dateShoten', function(date) {
  moment.locale('th')
  if (date && date !== '0') {
    // console.log(date)
    return moment(date).format('DD/MM/YY')
  } else return ''
})

Vue.filter('dayByDate', function(date) {
  moment.locale('th')
  if (date && date !== '0') {
    // console.log(date)
    return moment(date).format('dddd')
  } else return ''
})

Vue.filter('dateAndMonth', function(date) {
  moment.locale('th')
  if (date && date !== '0') {
    // console.log(date)
    return moment(date).format('D MMM')
  } else return ''
})
