var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container web-content"},[(_vm.error.statusCode === 404)?_c('div',{attrs:{"id":"notfound"}},[_vm._m(0)]):_vm._e(),_vm._v(" "),(
        _vm.error.statusCode === 500 ||
          _vm.error.statusCode === 502 ||
          _vm.error.statusCode === 503 ||
          _vm.error.statusCode === 504
      )?_c('div',{attrs:{"id":"notfound"}},[_vm._m(1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notfound"},[_c('div',{staticClass:"notfound-404"},[_c('h1',[_vm._v("404")])]),_vm._v(" "),_c('h2',[_vm._v("We are sorry, Page not found!")]),_vm._v(" "),_c('p',{staticClass:"mt-4"},[_vm._v("\n          The page you are looking for might have been removed had its name\n          changed or is temporarily unavailable.\n        ")]),_vm._v(" "),_c('a',{staticClass:"mt-4 button-blue-gradient",attrs:{"href":"/"}},[_vm._v("Back To Homepage")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notfound"},[_c('div',{staticClass:"notfound-404"},[_c('h1')]),_vm._v(" "),_c('div',{staticClass:"d-block text-center"},[_c('img',{staticClass:"mx-auto w-100",attrs:{"src":"/images/logo@2x.png"}})]),_vm._v(" "),_c('h2',{staticClass:"mt-5"},[_vm._v("ระบบขัดข้องชั่วคราว")]),_vm._v(" "),_c('p',{staticClass:"mt-4"},[_vm._v("\n          ขออภัยในความไม่สะดวก เว็บไซต์ Dezpax\n          จะกลับมาใช้งานได้อีกครั้งในไม่ช้า กรุณารอสักครู่ค่ะ\n        ")]),_vm._v(" "),_c('a',{staticClass:"mt-4 button-blue-gradient",attrs:{"href":"https://lin.ee/K0PgOze"}},[_vm._v("ติดต่อเจ้าหน้าที่")])])
}]

export { render, staticRenderFns }