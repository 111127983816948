export const state = () => ({
  totalPrice: 0,
  discountSpecial: 0
})

export const mutations = {
  updateTotalPrice(state, value) {
    state.totalPrice = value
  },
  updateDiscountSpecial(state, value) {
    state.discountSpecial = value
  }
}
export const actions = {
  getTotalPrice(context) {
    context.commit('totalPrice', 0)
  },
  getDiscountSpecial(context) {
    context.commit('discountSpecial', 0)
  }
}
