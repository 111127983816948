const cookieSetting = {}

cookieSetting.install = function(Vue, options) {
  Vue.prototype.checkCookieSetting = function() {
    let breakCheck = false
    const cookiesSetting = this.$cookies.get('dezpax_cookies')
    // console.log('dezpax_cookies', cookiesSetting)
    if (!breakCheck && cookiesSetting !== undefined) {
      breakCheck = true
      if (!cookiesSetting.analytic) {
        this.$cookies.remove('_ga')
        this.$cookies.remove('_gid')
        this.$cookies.remove('_gat')
        this.$cookies.remove('_gat_UA-121417492-1')
        this.$cookies.remove('_gat_gtag_UA_121417492_1')
        this.$cookies.remove('_gat_UA-121417492-4')
        this.$cookies.remove('_gat_gtag_UA_121417492_4')
        this.$cookies.remove('_hjid')
        this.$cookies.remove('_hjIncludedInSample')
        this.$cookies.remove('_hjAbsoluteSessionInProgress')
      }

      if (!cookiesSetting.targeting) {
        this.$cookies.remove('_fbp')
      }
    }
  }
}

export default cookieSetting
